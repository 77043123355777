<template>
    <section class="bg-white pb-100 mt-5">
        <div class="container py-3 px-5 px-lg-0 ly-lg-0">
            <compliance-warning />
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Lånebelopp</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p v-if="model.isBroker">Hos {{ document.data.title }} kan du ansöka om lån mellan <strong>{{ model.amountString }}</strong> från <strong>{{ model.connectedBanks }}</strong> olika långivare. Återbetalningstiden på dessa långivare varierar mellan <strong>{{ model.getDurationString() }}</strong>.</p>
                    <p v-else>Hos {{ document.data.title }} kan du låna {{ model.amountString }} med en valbar återbetalningstid på <strong>{{ model.getDurationString() }}</strong>. Tänk på att du alltid kan betala tillbaka lånet i förtid.</p>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Avgifter &amp; ränta</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p v-if="!model.isBroker">
                        {{ model.setupFee === 0 && model.administrationFee === 0 ? 
                            `${document.data.title} tar inte ut sedvanliga avgifter så som uppläggnings- eller administrationsavgift.` : '' }}
                        {{ model.setupFee === 0 && model.administrationFee > 0 ? 
                            `${document.data.title} tar inte ut någon uppläggninsavgift men har en administrationsavgift på ${model.administrationFeeString} per månad.` : '' }}
                        {{ model.setupFee > 0 && model.administrationFee > 0 ? 
                            `${document.data.title} har en uppläggningsavgift på ${model.setupFeeString} och en administrationsavgift på ${model.administrationFeeString} per månad.` : '' }}
                        Vi har sammanställt alla avgifter i tabellen nedan.
                    </p>

                    <p v-if="model.isFixedInterest">
                        Krediträntan är fast på {{ model.interestString }}, det innebär en effektiv ränta på <strong>{{ model.effectiveInterestString }}</strong>. 
                    </p>
                    <p v-else>
                        Krediträntan är mellan {{ model.interestString }}, det betyder att den effektiva räntan är mellan <strong>{{ model.effectiveInterestString }}</strong>. Vilken du erbjuds prövas individuellt och bestäms efter sedvanlig kreditprövning.
                    </p>

                    <p v-if="model.isBroker">
                        Avgifter (som t ex faktureringsavgift, aviseringsavgift mm) bestäms inte av {{ document.data.title }}, utan är beroende av vilken bank man som låntagare i slutändan väljer.
                    </p>

                    <table v-else class="table">
                        <tbody>
                            <lender-table-row-data title="Administrationsavgift" :value="model.administrationFeeString" />

                            <lender-table-row-data title="Förlängningsavgift" :value="model.extensionFeeString" />

                            <lender-table-row-data title="Förseningsavgift" :value="model.latePaymentFeeString" />

                            <lender-table-row-data title="Påminnelseavgift" :value="model.reminderFeeString" />

                            <lender-table-row-data title="Uppläggningsavgift" :value="model.setupFeeString" />

                            <lender-table-row-data title="Dröjsmålsränta" :value="model.penaltyInterestString" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Kreditupplysning & Krav</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p v-if="model.isBroker && model.takesUC">
                        {{ document.data.title }} gör sina kreditupplysningar via <strong>UC</strong>, då {{ document.data.title }} är en låneförmedlare samlas alla bankers kreditupplysningar under en.
                    </p>
                    <p v-else-if="model.isBroker || model.takesUC">
                        {{ document.data.title }} gör sina kreditupplysningar via <strong>{{ $getCreditCheckString(model.creditCheck) }}</strong>.
                    </p>
                    <p v-else>
                        {{ document.data.title }} gör sina kreditupplysningar via <strong>{{ $getCreditCheckString(model.creditCheck) }}</strong>, det tas alltså <strong>inte någon kreditupplysning med UC</strong>.
                    </p>

                    <p v-if="model.acceptsPaymentRemarks">
                        Betalningsanmärkningar <b>kan accepteras</b>, men inget skuldsaldo hos Kronofogden.
                    </p>
                    <p v-else>
                        Betalningsanmärkningar <b>accepteras inte</b>, och inte heller något skuldsaldo hos Kronofogden.
                    </p>
                </div>
            </div>
            <div v-if="model.hasDirectPayments" class="row pb-5">
                <div class="col-lg-4">
                    <h3>Direktutbetalning</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p>
                        {{ document.data.title }} <b>kan göra direktutbetalningar</b>, se tabellen nedan med fullständig information. 
                        {{ model.hasDirectPayments24Hours ? `${document.data.title} gör utbetalningarna dygnet runt.` : '' }}
                    </p>
                    <table class="table">
                        <tbody>
                            <lender-table-row-data title="SEB" :value="model.hasDirectPaymentToSEB ? 'Ja' : 'Nej'" />
                            
                            <lender-table-row-data title="Nordea" :value="model.hasDirectPaymentToNordea ? 'Ja' : 'Nej'" />
   
                            <lender-table-row-data title="Handelsbanken" :value="model.hasDirectPaymentToHandelsbanken ? 'Ja' : 'Nej'" />
                            
                            <lender-table-row-data title="Swedbank" :value="model.hasDirectPaymentToSwedbank ? 'Ja' : 'Nej'" />
                            
                            <lender-table-row-data title="Danske Bank" :value="model.hasDirectPaymentToDanskeBank ? 'Ja' : 'Nej'" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Öppettider</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p><strong>{{ document.data.title }} erbjuder kundtjänst under följande tider.</strong></p>
                    <table class="table">
                        <tbody>
                            <lender-table-row-data title="Måndag" :value="model.openingHours.monday || 'Stängt' " />        
                            
                            <lender-table-row-data title="Tisdag" :value="model.openingHours.tuesday || 'Stängt' " />
                            
                            <lender-table-row-data title="Onsdag" :value="model.openingHours.wednesday || 'Stängt' " />
                            
                            <lender-table-row-data title="Torsdag" :value="model.openingHours.thursday || 'Stängt' " />
                            
                            <lender-table-row-data title="Fredag" :value="model.openingHours.friday || 'Stängt' " />

                            <lender-table-row-data title="Lördag" :value="model.openingHours.saturday || 'Stängt' " />
                            
                            <lender-table-row-data title="Söndag" :value="model.openingHours.sunday || 'Stängt' " />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Kontakt</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <p>Kontaktuppgifter till <strong>{{ model.company.name }}</strong>.</p>
                    <table class="table">
                        <tbody>
                            <lender-table-row-data title="Telefon" :value="model.company.phone" />
                            
                            <lender-table-row-data title="E-post" :value="model.company.email" />

                            <lender-table-row-data v-if="model.company.website" title="Hemsida" :value="model.company.website" />

                            <lender-table-row-data title="Adress" :value="`${model.company.addres} <br>
                             ${model.company.postalNumber} ${model.company.county}`" 
                            />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-4">
                    <h3>Ansök</h3>
                </div>
                <div class="col-lg-8 mt-3 mt-md-0">
                    <out-link :document="document" class="btn btn-md btn-primary rounded-pill" />
                    <div class="clearfix mt-5" />
                    <span class="badge badge-soft-primary">OBS!</span>
                    <p class="mt-2">
                        För att ansöka om ett lån via {{ document.data.title }} behöver du vara minst <strong>{{ model.minAge }} år</strong> gammal.
                    </p>
                    <div class="clearfix mt-5" />
                    <span class="badge badge-soft-primary">Representativt exempel</span>
                    <p class="mt-2">{{ model.complianceExample }}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ComplianceWarning from './ComplianceWarning.vue';
import LenderTableRowData from './LenderTableRowData.vue';

export default {
    components: {
        ComplianceWarning,
        LenderTableRowData,

    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
};
</script>

<style lang="scss" scoped>
    div.col-lg-8 {
        font-size: 1.1em !important;

        & .table {
            margin-top: 40px;

            & :first-child {
                margin-top: 0;
            }

        }
        & ::v-deep .table td, ::v-deep .table th {
            font-weight: normal;
            font-size: 1.0em !important;

        }

    }
</style>